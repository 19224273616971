import React from 'react';
import { Link } from 'react-router-dom';

import Head from 'src/components/common/Head';
import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { TIMELINE_ROUTE } from 'src/constants/routes';
import mainImageDesktop from 'src/assets/main-img-desktop.svg';
import mainImageMobile from 'src/assets/main-img-mobile.svg';
import mainBgMobile from 'src/assets/main-bg-mobile-transparent.png';
import { useResponsive } from 'src/hooks/responsive';
import Icon from 'src/components/common/Icon';

import css from './Main.module.scss';

const Main = () => {
  const { seo, og } = useAppSelector(selectConfig).seo_og;
  const [isMobile] = useResponsive(['MOBILE']);
  const N12Link = 'http://www.mako.co.il/collab/makoapps.html?app=news';

  const seoTitle = seo.mainPage.title;
  const seoDescription = seo.mainPage.description;
  return (
    <>
      <Head seo={{ title: seoTitle, description: seoDescription }} og={og.mainPage}></Head>
      <div className={css.bg} style={isMobile ? { backgroundImage: `url(${mainBgMobile})` } : {}}></div>
      {typeof isMobile !== 'undefined' && isMobile && (
        <a href={N12Link} target="_blank" className={css.n12Logo}>
          <Icon type="n12-logo-black" />
        </a>
      )}
      <div className={css.main}>
        {typeof isMobile !== 'undefined' && <img src={isMobile ? mainImageMobile : mainImageDesktop} alt="" />}
        <p className={css.text}>
          מספר מחבלים חדרו לשטח ישראל משטח רצועת עזה, תושבי עוטף עזה מתבקשים להישאר בבתים בעקבות חשד לאירוע ביטחוני.
          מספר מחבלים חדרו לשטח ישראל משטח רצועת עזה, תושבי עוטף עזה מתבקשים להישאר בבתים בעקבות חשד.
        </p>
        <Link to={TIMELINE_ROUTE} className={css.link}>
          {' '}
          כניסה &gt;&gt;
        </Link>

        <div className={css.rabbiCredits}>
          Developed by{' '}
          <a href="https://www.rabbi.co.il/" target="_blank">
            Rabbi Interactive
          </a>
        </div>
      </div>
    </>
  );
};

export default () => {
  return (
    <>
      <Main />
    </>
  );
};
