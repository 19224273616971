import React from 'react';

import { useResponsive } from 'src/hooks/responsive';
import { analytics } from 'src/utils';
import mainLogo from 'src/assets/header/main-logo.svg';
import Shares from 'src/components/common/Shares';
import Icon from 'src/components/common/Icon';
import css from './Header.module.scss';
import ActionButton from '../ActionButton';

interface Props {
  onActionBtnClick?: () => void;
  isButtonDisabled?: boolean;
}

const Header = ({ onActionBtnClick, isButtonDisabled = true }: Props) => {
  const [isMobile] = useResponsive(['MOBILE', 'TABLET']);
  const N12Link = 'https://www.n12.co.il';

  return (
    <header className={css.header}>
      {isMobile ? (
        <>
          <Icon type="7-10-logo-small" />
          <ActionButton onClick={onActionBtnClick} isDisabled={isButtonDisabled} />
        </>
      ) : (
        <>
          <a href={N12Link} target="_blank" className={css.n12Logo} onClick={() => analytics.gtag.event('Click logo')}>
            <Icon type="n12-logo" />
          </a>
          <Icon type="7-10-logo-no-text" className={css.centeredLogo} />
          <Shares />
        </>
      )}
    </header>
  );
};

export default Header;
