import { createTheme, alpha } from '@mui/material';

const font = {
  regular: 'Yonit-Regular',
  bold: 'Yonit-Bold',
  medium: 'Yonit-Medium',
  light: 'Yonit-Light',
  digital: 'Digital Display',
};

export const theme = createTheme({
  direction: 'rtl',
  typography: {
    htmlFontSize: 10,
    fontFamily: font.regular,
    allVariants: {
      color: '#fff',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#053550',
      light: '#1E73A3',
    },
    error: {
      main: '#E42323',
    },
    background: {
      default: '#191d20',
      paper: '#FAFAF3',
    },
    other: {
      formBorder: '#053550',
      formBorderLight: alpha('#04476D', 0.5),
      formBackground: '#F3F3EA',
      formBackgroundLight: alpha('#F3F3EA', 0.5),
      formBackgroundDark: '#ddddcf',
    },
  },
  breakpoints: {
    values: {
      xs: 370,
      sm: 450,
      md: 767,
      lg: 1153,
      xl: 1531,
    },
  },
});

export default theme;
