import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { REDUX_STORAGE_KEY } from 'src/constants/other';
import api from './api';
import config from './config';
import timeline from './timeline';

// @ts-expect-error REDUX_STORAGE_KEY is provided to window by SSR
const _window: typeof globalThis & { [REDUX_STORAGE_KEY]: any } = globalThis || {};
const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  config,
  timeline,
});

export const makeStore = () =>
  configureStore({
    reducer: ((state, action) => {
      if (action.type === 'HYDRATE') {
        return { ...state, ...action.payload };
      } else {
        return combinedReducer(state, action);
      }
    }) as typeof combinedReducer,
    preloadedState: _window[REDUX_STORAGE_KEY],
    middleware: (gDM) =>
      gDM({
        serializableCheck: {
          ignoredActions: [],
          ignoredPaths: [],
        },
      }).concat(api.middleware),
  });

export const store = makeStore();
export const reducer = combinedReducer;

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = AppStore['dispatch'];
