import axios from 'axios';
import cryptoJS from 'crypto-js';

export const getSecuredSrc = async (url: string) => {
  const mediaUrl = url;

  const data = JSON.stringify({
    da: 'rabbiOct7th',
    lp: mediaUrl.replace(/^.*\/\/[^/]+/, ''),
    na: '1.0',
    rv: 'AKAMAI',
  });

  const tokenKey = cryptoJS.enc.Utf8.parse('HW1lPZgu+si3pMs3irBIjQ==');
  const vendorKey = cryptoJS.enc.Utf8.parse('theExact16Chars=');
  const encryptedData = cryptoJS.AES.encrypt(data, tokenKey, {
    iv: vendorKey,
  }).toString();

  const ticketUrl = 'https://mass.mako.co.il/ClicksStatistics/entitlementsServicesV2.jsp?et=rgt';

  const res = await axios.post(ticketUrl, encryptedData, {
    headers: {
      'content-type': 'text/plain;charset=UTF-8',
    },
  });

  if (!res.data) {
    return;
  }

  const tokenData = cryptoJS.AES.decrypt(res.data.trim(), tokenKey, {
    iv: vendorKey,
  });
  const token = JSON.parse(tokenData.toString(cryptoJS.enc.Utf8)).tickets[0];
  const fetchUrlSign = mediaUrl.indexOf('?') > -1 ? '&' : '?';

  const src = mediaUrl + fetchUrlSign + token?.ticket;

  // console.info('video src', src);
  return src;
};
