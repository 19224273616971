import React from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import cn from 'classnames';
import { parseISO } from 'date-fns';
import Slider from 'react-slick';

import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { selectTimeline } from 'src/store/timeline/selectors';
import { TimelineItem } from 'src/store/timeline/actions/init';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getSecuredSrc } from './helpers/get-video-url';
import { analytics } from 'src/utils';
import Head from 'src/components/common/Head';
import Header from 'src/components/common/Header';
import ActionButton from 'src/components/common/ActionButton';
import Cooladata from 'src/components/common/Cooladata';
import VideoJSPlayer from './VideoJSPlayer';
import { DefaultNextArrow, DefaultPrevArrow, ExternalNextArrow, ExternalPrevArrow } from './Arrows';

import css from './Timeline.module.scss';

const DesktopApp = () => {
  const { seo, og } = useAppSelector(selectConfig).seo_og;
  const data = useAppSelector(selectTimeline);
  const [initialIndex, setInitialIndex] = React.useState<number>(0);
  const [isFirstView, setIsFirstView] = React.useState(true);
  const [activeIndex, setActiveIndex] = React.useState<number>(-1);
  const [sliderActiveIndex, setSliderActiveIndex] = React.useState<number>(0);
  const [animate, setAnimate] = React.useState<boolean>(false);
  const [activeSlideData, setActiveSlideData] = React.useState<any>(null);
  const [activeSlideUrl, setActiveSlideUrl] = React.useState<string | null>(null);
  const [currentTimeID, setCurrentTimeID] = React.useState<string>('');
  const timelineSliderRef = React.useRef<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    const fetchSecureUrl = async (videoUrl: string) => {
      const securedUrl = await getSecuredSrc(videoUrl);
      if (securedUrl) {
        setActiveSlideUrl(securedUrl);
      }
    };

    if (data && data[activeIndex]) {
      fetchSecureUrl(data[activeIndex].videoUrl);
      // fetchSecureUrl(
      //   'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
      // );
    }
  }, [activeIndex, data]);

  React.useEffect(() => {
    if (timelineSliderRef.current && data && data.length > 0 && isFirstView) {
      const initItemResult = findItemForCurrentTime(data);
      if (initItemResult && initItemResult.index !== undefined) {
        setInitialIndex(data.length - 1 - initItemResult.index);
        setActiveSlide(initItemResult.index);
        setCurrentTimeID(initItemResult.item._id);
      } else {
        setActiveSlide(0);
      }
      setAnimate(true);
    }
  }, [timelineSliderRef, data, isFirstView]);

  const setActiveSlide = (index: number) => {
    setActiveSlideData(data[index]);
    setActiveIndex(index);
    setSearchParams({ name: data[index].name });
    // setTimeout(() => setAnimate(true), 300);

    /* for slider rtl correct position revert active index: */
    const indexFromEnd = data.length - 1 - index;
    if (indexFromEnd >= 3) {
      moveSlides(indexFromEnd - 3);
    }

    window.scrollTo(0, 0);
  };

  const moveSlides = React.useCallback(
    // prevent too many clicks per second
    _.throttle((index: number) => {
      setSliderActiveIndex(index);
      timelineSliderRef.current.slickGoTo(index);
    }, 1100), // 1000s slider animation
    [],
  );

  const bindVideoCompleted = () => {
    if (activeIndex + 1 === data.length) {
      setActiveSlide(0);
    } else {
      setActiveSlide(activeIndex + 1);
    }
    setIsFirstView(false);
  };

  const onVideoCompleted = () => {
    bindVideoCompleted();
  };

  const onSlideClick = (index: number, name: string) => {
    analytics.gtag.event('timline_event_click', { CUSTOM_PARAMETER: name });
    setActiveSlide(index);
    setIsFirstView(false);
  };

  const onPrevControlClick = (e: React.MouseEvent<HTMLDivElement>) => {
    analytics.gtag.event('event_transfer_arrows');
    setActiveSlide(activeIndex - 1);
    setIsFirstView(false);
  };

  const onNextControlClick = (e: React.MouseEvent<HTMLDivElement>) => {
    analytics.gtag.event('event_transfer_arrows');
    setActiveSlide(activeIndex + 1);
    setIsFirstView(false);
  };

  const onSliderPrevControlClick = (e: React.MouseEvent<HTMLDivElement>) => {
    analytics.gtag.event('timline_arrows');
    moveSlides(sliderActiveIndex + 5);
  };

  const onSliderNextControlClick = (e: React.MouseEvent<HTMLDivElement>) => {
    analytics.gtag.event('timline_arrows');
    if (sliderActiveIndex < 5) {
      moveSlides(0);
    } else {
      moveSlides(sliderActiveIndex - 5);
    }
  };

  const timelineSliderSettings = {
    arrows: false,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 6,
    infinite: false,
    initialSlide: initialIndex,
    touchThreshold: 10,
    cssEase: 'ease-in-out',
    className: 'timeline-slider',
    rtl: true,
    swipe: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const onArticleClick = () => {
    analytics.gtag.event('click_article_teaser');
  };

  return (
    <>
      <Head seo={seo} og={og}></Head>
      <Header />
      <div className={css.gradient} />
      <div className={css.content}>
        <div className={css.fixedSection}>
          <div className={css.sliderTimeline}>
            <DefaultNextArrow onClick={onSliderNextControlClick} className={sliderActiveIndex === 0 && 'disabled'} />
            <Slider {...timelineSliderSettings} ref={(slider) => (timelineSliderRef.current = slider)}>
              {data.map((item, index) => (
                <div
                  key={item._id}
                  className={cn(css.timelineItem, index === activeIndex && css.active)}
                  onClick={() => onSlideClick(index, item.name)}
                >
                  <div className={css.timelineItemInner}>
                    <div className={css.time}>{formatDate(item.date)}</div>
                    <div className={css.square} />
                    <div className={css.name}>{item.name}</div>
                  </div>
                </div>
              ))}
            </Slider>
            <DefaultPrevArrow
              onClick={onSliderPrevControlClick}
              className={detectDisabled(sliderActiveIndex, data.length, timelineSliderRef) && 'disabled'}
            />
          </div>
          {activeSlideData && (
            <ActionButton onClick={() => setIsFirstView(true)} isDisabled={currentTimeID === activeSlideData._id} />
          )}

          <div className={css.player}>
            {activeSlideUrl && activeSlideData && (
              <VideoJSPlayer
                videoSRC={activeSlideUrl}
                startFrom={isFirstView ? getTimeDifference(activeSlideData.date) : 0}
                onComplete={onVideoCompleted}
                id={activeSlideData._id}
                isDefaultControls
                isCenteredPlayBtn
              />
            )}
          </div>
        </div>
        <div className={css.scrollableSection}>
          {activeSlideData && (
            <div className={css.scrollableContent}>
              <div className={css.timer}>{formatDate(activeSlideData.date)}</div>
              <h1>{activeSlideData.title}</h1>
              {activeSlideData && (
                <div className={css.description} dangerouslySetInnerHTML={{ __html: activeSlideData.description }} />
              )}

              <div className={css.articles}>
                <CSSTransition
                  in={animate}
                  timeout={300}
                  classNames={{
                    enter: css.enter,
                    enterActive: css.enterActive,
                    exit: css.exit,
                    exitActive: css.exitActive,
                  }}
                  unmountOnExit
                >
                  <>
                    {activeSlideData &&
                      activeSlideData.articles &&
                      activeSlideData.articles.map((article: any, index: number) => (
                        <a
                          href={article.link}
                          target="_blank"
                          key={index}
                          className={css.articleItem}
                          onClick={onArticleClick}
                        >
                          <img src={fileLink(article.poster)} alt={article.title} className={css.poster} />
                          <div className={css.title}>{article.title}</div>
                        </a>
                      ))}
                  </>
                </CSSTransition>
              </div>

              <div className={css.externalArrowControls}>
                <ExternalPrevArrow onClick={onPrevControlClick} className={activeIndex === 0 && 'disabled'} />
                <ExternalNextArrow
                  onClick={onNextControlClick}
                  className={activeIndex === data.length - 1 && 'disabled'}
                />
              </div>
            </div>
          )}
        </div>
        <div className={css.rabbiCredits}>
          Developed by{' '}
          <a href="https://www.rabbi.co.il/" target="_blank">
            Rabbi Interactive
          </a>
        </div>
      </div>

      {activeSlideData && <Cooladata pageId="content" time={formatDate(activeSlideData.date)} />}
    </>
  );
};

export default DesktopApp;

function detectDisabled(activeSliderIndex: number, dataLength: number, timelineSliderRef: any) {
  if (timelineSliderRef && timelineSliderRef.current) {
    if (timelineSliderRef.current.state.breakpoint === 1280) {
      return dataLength - 4 <= activeSliderIndex;
    } else {
      return dataLength - 6 <= activeSliderIndex;
    }
  }
}

function timeStringToSeconds(timeString: string) {
  const timeParts = timeString.split(':').map(Number);
  const seconds = timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2];
  return seconds;
}

function formatDate(date: string) {
  const _date = new Date(date);
  return _date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
}

function getTimeDifference(dateString: string) {
  // Parse the input date string into a Date object
  const targetDate = parseISO(dateString);

  // Convert startTimeString ('hh:mm:ss') to total seconds
  // const [hours, minutes, seconds] = startTimeString.split(':').map(Number);
  // const startTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

  // Get the current date
  const current = new Date();
  const currentTimeInSeconds = current.getUTCHours() * 3600 + current.getUTCMinutes() * 60 + current.getUTCSeconds();

  const timeInSeconds = targetDate.getUTCHours() * 3600 + targetDate.getUTCMinutes() * 60 + targetDate.getUTCSeconds();

  // Calculate the difference in seconds between currentDate and adjustedDate
  const differenceInSec = currentTimeInSeconds - timeInSeconds;

  // Convert the difference to hh:mm:ss format
  // const diffHours = Math.floor(Math.abs(differenceInSec) / 3600)
  //   .toString()
  //   .padStart(2, '0');
  // const diffMinutes = Math.floor((Math.abs(differenceInSec) % 3600) / 60)
  //   .toString()
  //   .padStart(2, '0');
  // const diffSeconds = (Math.abs(differenceInSec) % 60).toString().padStart(2, '0');
  // const differenceString = `${diffHours}:${diffMinutes}:${diffSeconds}`;
  console.info({
    // differenceInSeconds: differenceInSec,
    // differenceFormatted: differenceString,
    // startTimeFormatted: startTimeString,
    startTime: differenceInSec,
  });
  return differenceInSec;
}

function findItemForCurrentTime(
  data: TimelineItem[],
): { item: TimelineItem; index: number; timeDifference?: number } | undefined {
  const current = new Date();

  // Extract current time in hours, minutes, and seconds
  const currentTimeInSeconds = current.getUTCHours() * 3600 + current.getUTCMinutes() * 60 + current.getUTCSeconds();

  // Convert item dates to time-only format for comparison
  const sortedData = data
    .map((item, index) => {
      const date = parseISO(item.date);
      const timeInSeconds = date.getUTCHours() * 3600 + date.getUTCMinutes() * 60 + date.getUTCSeconds();

      return {
        ...item,
        timeInSeconds,
        originalIndex: index, // Preserve original index
      };
    })
    .sort((a, b) => a.timeInSeconds - b.timeInSeconds);

  // Find the item with a time within the current time and the next item’s time
  for (let i = 0; i < sortedData.length; i++) {
    const item = sortedData[i];
    const nextItem = sortedData[i + 1];

    if (item.timeInSeconds <= currentTimeInSeconds && (!nextItem || currentTimeInSeconds < nextItem.timeInSeconds)) {
      return {
        item: {
          ...item,
          date: item.date, // Keep the original date
        },
        index: item.originalIndex, // Return the original index
        timeDifference: currentTimeInSeconds - item.timeInSeconds,
      };
    }
  }

  return undefined; // No item found within the given range
}

export const fileLink = (data: string) => {
  if (data.includes('googleapis')) {
    return data.replace(
      'https://storage.googleapis.com/mako-n12-7-10-video.appspot.com/',
      'https://october7th-n12.cdn-il.com/',
    );
  } else {
    return `https://october7th-n12.cdn-il.com/${data}`;
  }
};

const data = [
  {
    _id: '64da2b4a9f1b0a001c4e4b20',
    name: 'Admin User 1',
    title: 'Breaking News 1',
    description: 'aaa',
    videoDuration: '02:20:15',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T01:33:00.000Z',
    articles: [
      {
        title: 'Article 1',
        poster: 'articles/sksksk.jpg',
        link: 'https://example.com/article1',
      },
    ],
    order: 1,
    createdAt: '2024-08-10T12:00:00Z',
    updatedAt: '2024-08-10T12:30:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b21',
    name: 'Admin User 2',
    title: 'Breaking News 2',
    description: 'aaa',
    videoDuration: '02:00:00',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T06:00:00.000Z',
    articles: [
      {
        title: 'Article 2',
        poster: 'https://example.com/poster2.jpg',
        link: 'https://example.com/article2',
      },
    ],
    order: 2,
    createdAt: '2024-08-10T12:15:00Z',
    updatedAt: '2024-08-10T12:45:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b22',
    name: 'Admin User 3',
    title: 'Breaking News 3',
    description: 'aaa',
    videoDuration: '01:02:45',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T12:20:00.000Z',
    articles: [
      {
        title: 'Article 3',
        poster: 'https://example.com/poster3.jpg',
        link: 'https://example.com/article3',
      },
    ],
    order: 3,
    createdAt: '2024-08-10T12:30:00Z',
    updatedAt: '2024-08-10T13:00:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b23',
    name: 'Admin User 4',
    title: 'Breaking News 4',
    description: 'aaa',
    videoDuration: '00:03:00',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T13:50:00.000Z',
    articles: [
      {
        title: 'Article 4',
        poster: 'https://example.com/poster4.jpg',
        link: 'https://example.com/article4',
      },
    ],
    order: 4,
    createdAt: '2024-08-10T12:45:00Z',
    updatedAt: '2024-08-10T13:15:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b24',
    name: 'Admin User 5',
    title: 'Breaking News 5',
    description: 'aaa',
    videoDuration: '00:04:15',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T13:05:00.000Z',
    articles: [
      {
        title: 'Article 5',
        poster: 'https://example.com/poster5.jpg',
        link: 'https://example.com/article5',
      },
    ],
    order: 5,
    createdAt: '2024-08-10T13:00:00Z',
    updatedAt: '2024-08-10T13:30:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b25',
    name: 'Admin User 6',
    title: 'Breaking News 6',
    description: 'aaa',
    videoDuration: '00:05:30',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T14:30:00.000Z',
    articles: [
      {
        title: 'Article 6',
        poster: 'https://example.com/poster6.jpg',
        link: 'https://example.com/article6',
      },
    ],
    order: 6,
    createdAt: '2024-08-10T13:15:00Z',
    updatedAt: '2024-08-10T13:45:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b26',
    name: 'Admin User 7',
    title: 'Breaking News 7',
    description: 'aaa',
    videoDuration: '00:06:45',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T23:40:00.000Z',
    articles: [
      {
        title: 'Article 7',
        poster: 'https://example.com/poster7.jpg',
        link: 'https://example.com/article7',
      },
    ],
    order: 7,
    createdAt: '2024-08-10T13:30:00Z',
    updatedAt: '2024-08-10T14:00:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b27',
    name: 'Admin User 8',
    title: 'Breaking News 8',
    description: 'aaa',
    videoDuration: '00:07:00',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T23:50:00.000Z',
    articles: [
      {
        title: 'Article 8',
        poster: 'https://example.com/poster8.jpg',
        link: 'https://example.com/article8',
      },
    ],
    order: 8,
    createdAt: '2024-08-10T13:45:00Z',
    updatedAt: '2024-08-10T14:15:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b28',
    name: 'Admin User 9',
    title: 'Breaking News 9',
    description: 'aaa',
    videoDuration: '00:07:00',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T23:55:00.000Z',
    articles: [
      {
        title: 'Article 8',
        poster: 'https://example.com/poster8.jpg',
        link: 'https://example.com/article8',
      },
    ],
    order: 9,
    createdAt: '2024-08-10T13:45:00Z',
    updatedAt: '2024-08-10T14:15:00Z',
  },
];
