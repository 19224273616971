import React from 'react';
import classNames from 'classnames';

import css from './Icon.module.scss';

export type IconType = 'whatsapp' | 'facebook' | 'email' | 'n12-logo' | 'arrow-left' | 'n12-logo-black' | 'copy';

interface Props {
  type: IconType;
  className?: string;
  width?: number;
  height?: number;
  color?: string;
  secondaryColor?: string;
}

const Icon = ({ type, className, width, height, color = '#000', secondaryColor, ...iconProps }: Props) => {
  let icon = null;
  const svgProps = {
    width,
    height,
    viewBox: `0 0 ${width || 38} ${height || 38}`,
    xmlns: 'http://www.w3.org/2000/svg',
  };

  switch (type) {
    case 'whatsapp':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_771_4100)">
            <path
              d="M19.0508 4.91C17.1808 3.03 14.6908 2 12.0408 2C6.58078 2 2.13078 6.45 2.13078 11.91C2.13078 13.66 2.59078 15.36 3.45078 16.86L2.05078 22L7.30078 20.62C8.75078 21.41 10.3808 21.83 12.0408 21.83C17.5008 21.83 21.9508 17.38 21.9508 11.92C21.9508 9.27 20.9208 6.78 19.0508 4.91ZM12.0408 20.15C10.5608 20.15 9.11078 19.75 7.84078 19L7.54078 18.82L4.42078 19.64L5.25078 16.6L5.05078 16.29C4.23078 14.98 3.79078 13.46 3.79078 11.91C3.79078 7.37 7.49078 3.67 12.0308 3.67C14.2308 3.67 16.3008 4.53 17.8508 6.09C19.4108 7.65 20.2608 9.72 20.2608 11.92C20.2808 16.46 16.5808 20.15 12.0408 20.15ZM16.5608 13.99C16.3108 13.87 15.0908 13.27 14.8708 13.18C14.6408 13.1 14.4808 13.06 14.3108 13.3C14.1408 13.55 13.6708 14.11 13.5308 14.27C13.3908 14.44 13.2408 14.46 12.9908 14.33C12.7408 14.21 11.9408 13.94 11.0008 13.1C10.2608 12.44 9.77078 11.63 9.62078 11.38C9.48078 11.13 9.60078 11 9.73078 10.87C9.84078 10.76 9.98078 10.58 10.1008 10.44C10.2208 10.3 10.2708 10.19 10.3508 10.03C10.4308 9.86 10.3908 9.72 10.3308 9.6C10.2708 9.48 9.77078 8.26 9.57078 7.76C9.37078 7.28 9.16078 7.34 9.01078 7.33C8.86078 7.33 8.70078 7.33 8.53078 7.33C8.36078 7.33 8.10078 7.39 7.87078 7.64C7.65078 7.89 7.01078 8.49 7.01078 9.71C7.01078 10.93 7.90078 12.11 8.02078 12.27C8.14078 12.44 9.77078 14.94 12.2508 16.01C12.8408 16.27 13.3008 16.42 13.6608 16.53C14.2508 16.72 14.7908 16.69 15.2208 16.63C15.7008 16.56 16.6908 16.03 16.8908 15.45C17.1008 14.87 17.1008 14.38 17.0308 14.27C16.9608 14.16 16.8108 14.11 16.5608 13.99Z"
              fill="#EEF1F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_771_4100">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case 'facebook':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_771_4097)">
            <path
              d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z"
              fill="#EEF1F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_771_4097">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case 'email':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_771_4107)">
            <path
              d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V8L12 13L20 8V18ZM12 11L4 6H20L12 11Z"
              fill="#EEF1F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_771_4107">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case 'n12-logo':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="74" height="33" viewBox="0 0 74 33" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6653 33H21.5516C20.0582 33 18.6891 32.1759 17.9978 30.8603L6.3131 8.68164V33H0V0.000155219H6.42681C7.91866 0.000155219 9.28923 0.822754 9.98058 2.13681L21.6653 24.3155V0.000155219H27.9768V33H21.6653ZM42.4921 0.000155219H37.0462L35.2451 3.47485H41.7295V29.9059H39.4114V5.72988H34.0747L32.4873 8.79552H36.0168V33H45.1211V2.52938C45.118 1.85512 44.8391 1.21083 44.3463 0.735849C43.8536 0.26087 43.1865 -0.0028415 42.4921 0.000155219ZM72.5855 29.9389H53.02L63.3872 14.4174C63.3948 14.4069 63.3993 14.4024 63.4054 14.3949C63.9542 13.6592 64.3378 12.8111 64.5258 11.9106L64.5334 11.8702C64.591 11.5525 64.6198 11.2289 64.6198 10.9052C64.6259 9.42033 64.0316 7.99689 62.9748 6.96453C60.7598 4.81438 57.2591 4.81438 55.044 6.96453C53.9873 7.99689 53.393 9.42033 53.399 10.9052H56.7785C56.7739 9.95525 57.3667 9.10568 58.2536 8.78953C59.1421 8.47337 60.1306 8.76106 60.7158 9.50574C61.301 10.2504 61.3556 11.2873 60.8507 12.0889C60.8401 12.1069 46.9268 33 46.9268 33H74L72.5855 29.9389ZM47.8288 11.0386H51.2158C51.2098 8.9978 52.0179 7.04094 53.4597 5.60851C56.4586 2.61629 61.2889 2.61629 64.2893 5.60851C66.85 8.15872 67.2685 12.1758 65.2899 15.2055L57.0756 27.6479H71.2922L69.8155 24.4594H63.084C63.084 24.4594 66.6878 19.2571 67.9462 17.1534C69.2045 15.0512 69.764 13.1333 69.8095 11.0236C69.817 10.2924 69.7443 9.56268 69.5942 8.84796C68.4586 3.28755 63.3372 -0.509287 57.7321 0.0555945C52.127 0.620476 47.8501 5.36278 47.8288 11.0386Z"
            fill="#EEF1F7"
          />
        </svg>
      );
      break;

    case 'n12-logo-black':
      icon = (
        <svg width="56" height="25" viewBox="0 0 56 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_954_9339)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.395 24.9999H16.309C15.1789 24.9999 14.1428 24.3756 13.6197 23.3789L4.77738 6.57682V24.9999H0V-9.60324e-05H4.86343C5.99238 -9.60324e-05 7.02954 0.623088 7.55271 1.61859L16.395 18.4207V-9.60324e-05H21.1712V24.9999H16.395ZM32.1555 -9.60324e-05H28.0344L26.6714 2.63226H31.5784V22.6559H29.8242V4.34062H25.7857L24.5845 6.66309H27.2554V24.9999H34.145V1.916C34.1427 1.40519 33.9316 0.917085 33.5587 0.55725C33.1858 0.197416 32.681 -0.00236628 32.1555 -9.60324e-05ZM54.9284 22.6808H40.1224L47.9677 10.9221C47.9734 10.9141 47.9769 10.9107 47.9815 10.9051C48.3968 10.3477 48.6871 9.70522 48.8293 9.02301L48.8351 8.99236C48.8787 8.75172 48.9005 8.50653 48.9005 8.26134C48.9051 7.13644 48.4553 6.05807 47.6556 5.27597C45.9794 3.64706 43.3303 3.64706 41.6541 5.27597C40.8544 6.05807 40.4047 7.13644 40.4093 8.26134H42.9666C42.9632 7.54168 43.4118 6.89806 44.0829 6.65855C44.7553 6.41904 45.5033 6.63698 45.9462 7.20114C46.389 7.7653 46.4303 8.5508 46.0483 9.15809C46.0402 9.17171 35.5114 24.9999 35.5114 24.9999H55.9989L54.9284 22.6808ZM36.1941 8.36237H38.7571C38.7525 6.81633 39.3641 5.33386 40.4552 4.24868C42.7245 1.98183 46.3798 1.98183 48.6504 4.24868C50.5882 6.18066 50.9048 9.22393 49.4076 11.5192L43.1915 20.9452H53.9498L52.8323 18.5297H47.7383C47.7383 18.5297 50.4654 14.5885 51.4177 12.9948C52.3699 11.4022 52.7933 9.94928 52.8277 8.35102C52.8334 7.79708 52.7784 7.24427 52.6648 6.70282C51.8054 2.49037 47.9298 -0.386039 43.6883 0.0419036C39.4467 0.469846 36.2101 4.06252 36.1941 8.36237Z"
              fill="#1A1D20"
            />
          </g>
          <defs>
            <clipPath id="clip0_954_9339">
              <rect width="56" height="25" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case 'arrow-left':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
          <path
            d="M1.20026 6.79289C0.80974 7.18342 0.80974 7.81658 1.20026 8.20711L7.56422 14.5711C7.95475 14.9616 8.58791 14.9616 8.97844 14.5711C9.36896 14.1805 9.36896 13.5474 8.97844 13.1569L3.32158 7.5L8.97844 1.84315C9.36896 1.45262 9.36896 0.819457 8.97844 0.428933C8.58791 0.0384084 7.95475 0.0384083 7.56423 0.428933L1.20026 6.79289ZM1.90747 6.5L1.90737 6.5L1.90737 8.5L1.90747 8.5L1.90747 6.5Z"
            fill="#EEF1F7"
          />
        </svg>
      );
      break;

    case 'copy':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_1002_1462)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 3C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V13C3 13.2652 3.10536 13.5196 3.29289 13.7071C3.48043 13.8946 3.73478 14 4 14H5C5.55228 14 6 14.4477 6 15C6 15.5523 5.55228 16 5 16H4C3.20435 16 2.44129 15.6839 1.87868 15.1213C1.31607 14.5587 1 13.7956 1 13V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H13C13.7956 1 14.5587 1.31607 15.1213 1.87868C15.6839 2.44129 16 3.20435 16 4V5C16 5.55228 15.5523 6 15 6C14.4477 6 14 5.55228 14 5V4C14 3.73478 13.8946 3.48043 13.7071 3.29289C13.5196 3.10536 13.2652 3 13 3H4ZM11 10C10.4477 10 10 10.4477 10 11V20C10 20.5523 10.4477 21 11 21H20C20.5523 21 21 20.5523 21 20V11C21 10.4477 20.5523 10 20 10H11ZM8 11C8 9.34315 9.34315 8 11 8H20C21.6569 8 23 9.34315 23 11V20C23 21.6569 21.6569 23 20 23H11C9.34315 23 8 21.6569 8 20V11Z"
              fill="#EEF1F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_1002_1462">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    default:
      break;
  }

  return (
    <i className={classNames(css.icon, className)} {...iconProps}>
      {icon}
    </i>
  );
};

Icon.defaultProps = {
  className: '',
  width: 38.945,
  height: 38.948,
  color: '#111',
  secondaryColor: '',
};

export default Icon;
