import React from 'react';

import { useResponsive } from 'src/hooks/responsive';
import DesktopApp from './Desktop';
import MobileApp from './Mobile';

const Timeline = () => {
  const [isMobile] = useResponsive(['MOBILE', 'TABLET']);
  return <>{isMobile ? <MobileApp /> : <DesktopApp />}</>;
};

export default Timeline;
