import React from 'react';
import cn from 'classnames';

import css from './ActionButton.module.scss';

const ActionButton = ({
  className,
  isFirstView,
  onClick,
}: {
  className?: string;
  isFirstView: boolean;
  onClick?: () => void;
}) => {
  return (
    <div className={cn(className, css.actionButton, isFirstView && css.hideBtn)} onClick={onClick}>
      עבור לזמן אמת
    </div>
  );
};

export default ActionButton;
