import React from 'react';
import cn from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { parseISO } from 'date-fns';

import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { selectTimeline } from 'src/store/timeline/selectors';
import { TimelineItem } from 'src/store/timeline/actions/init';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getSecuredSrc } from './get-video-url';
import { useResponsive } from 'src/hooks/responsive';
import Head from 'src/components/common/Head';
// import { Loader } from 'src/components/common/Loader';
import Header from 'src/components/common/Header';
import VideoJSPlayer from './VideoJSPlayer';
import { ExternalNextArrow, ExternalPrevArrow } from './Arrows';

import css from './Mobile.module.scss';
import Shares from 'src/components/common/Shares';

const MobileApp = () => {
  const { seo, og } = useAppSelector(selectConfig).seo_og;
  const [isMobile] = useResponsive(['MOBILE']);
  const data = useAppSelector(selectTimeline);
  const [isFirstView, setIsFirstView] = React.useState(true);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [animate, setAnimate] = React.useState<boolean>(false);
  const [activeSlideData, setActiveSlideData] = React.useState<any>(null);
  const [activeSlideUrl, setActiveSlideUrl] = React.useState<string | null>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const fixedContentRef = React.useRef<HTMLDivElement>(null);
  const timelineRef = React.useRef<HTMLDivElement>(null);
  const [fixedSectionH, setFixedSectionH] = React.useState<number>(0);
  const [isFixedContentMinified, setIsFixedContentMinified] = React.useState<boolean>(false);
  const [playerIsReady, setPlayerIsReady] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const seoTitle = seo.mainPage.title;
  const seoDescription = seo.mainPage.description;

  const onScroll = () => {
    if (window.scrollY > 0) {
      setIsFixedContentMinified(true);
    } else {
      setIsFixedContentMinified(false);
    }
  };

  React.useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', onScroll);

      // Clean-up
      return () => {
        window.removeEventListener('scroll', onScroll);
        setIsFixedContentMinified(false);
        setFixedSectionH(0);
      };
    }
  }, [isMobile]);

  React.useEffect(() => {
    if (contentRef.current && fixedContentRef.current && playerIsReady && isMobile) {
      const { clientHeight } = fixedContentRef.current;

      setFixedSectionH(clientHeight);
    }
  }, [contentRef.current, fixedContentRef.current, playerIsReady, isMobile]);

  React.useEffect(() => {
    if (timelineRef.current) {
      const itemWidth = timelineRef.current?.scrollWidth / data.length;

      timelineRef.current.scrollTo({
        behavior: 'smooth',
        left: -(activeIndex * itemWidth - window.innerWidth / 2 + itemWidth / 2),
      });
    }
  }, [timelineRef.current, activeIndex]);

  React.useEffect(() => {
    const fetchSecureUrl = async (videoUrl: string) => {
      const securedUrl = await getSecuredSrc(videoUrl);
      if (securedUrl) {
        setActiveSlideUrl(securedUrl);
      }
    };

    if (data && data[activeIndex]) {
      fetchSecureUrl(data[activeIndex].videoUrl);
      // fetchSecureUrl(
      //   'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
      // );
    }
  }, [activeIndex, data]);

  React.useEffect(() => {
    if (data && data.length > 0 && isFirstView) {
      const initItemResult = findItemForCurrentTime(data);
      if (initItemResult && initItemResult.index !== undefined) {
        setActiveSlide(initItemResult.index);
      } else {
        setActiveSlideData(data[0]);
      }
      setAnimate(true);
    }
  }, [data, isFirstView]);

  const setActiveSlide = (index: number) => {
    setActiveSlideData(data[index]);
    setActiveIndex(index);
    setSearchParams({ name: data[index].name });
    setTimeout(() => setAnimate(true), 300);
    window.scrollTo(0, 0);
  };

  const bindVideoCompleted = () => {
    if (activeIndex + 1 === data.length) {
      setActiveSlide(0);
    } else {
      setActiveSlide(activeIndex + 1);
    }
    setIsFirstView(false);
  };

  const onVideoCompleted = () => {
    bindVideoCompleted();
  };

  const onSlideClick = (index: number) => {
    setActiveSlide(index);
    setIsFirstView(false);
  };

  const onPrevControlClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setActiveSlide(activeIndex - 1);
    setIsFirstView(false);
  };

  const onNextControlClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setActiveSlide(activeIndex + 1);
    setIsFirstView(false);
  };

  return (
    <>
      <Head seo={{ title: seoTitle, description: seoDescription }} og={og.mainPage}></Head>
      <Header onActionBtnClick={() => setIsFirstView(true)} isFirstView={isFirstView} />
      {/* {activeSlideData ? ( */}
      <div className={css.content} ref={contentRef}>
        <div className={css.fixedSection} ref={fixedContentRef}>
          <div className={cn(css.playerSection, isFixedContentMinified && css.isMinified)}>
            {activeSlideData && <div className={css.timer}>{formatDate(activeSlideData.date)}</div>}
            <div className={css.player}>
              {activeSlideUrl && activeSlideData && (
                <VideoJSPlayer
                  videoSRC={activeSlideUrl}
                  startFrom={isFirstView ? getTimeDifference(activeSlideData.date) : 0}
                  onComplete={onVideoCompleted}
                  id={activeSlideData._id}
                  isDefaultControls
                  isCenteredPlayBtn
                  onReady={() => setPlayerIsReady(true)}
                />
              )}
            </div>
          </div>
          <div className={css.timeline}>
            <div className={css.timelineScrollable} ref={timelineRef}>
              {data.map((item, index) => (
                <div
                  key={item._id}
                  id={item._id}
                  className={cn(css.timelineItem, index === activeIndex && css.active)}
                  onClick={() => onSlideClick(index)}
                >
                  <div className={css.timelineItemInner}>
                    <div className={css.time}>{formatDate(item.date)}</div>
                    <div className={css.square} />
                    <div className={css.name}>{item.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={css.scrollableSection} style={{ paddingTop: `${fixedSectionH}px` }}>
          {activeSlideData && <div className={css.timer}>{formatDate(activeSlideData.date)}</div>}
          <CSSTransition
            in={animate}
            timeout={300}
            classNames={{
              enter: css.enter,
              enterActive: css.enterActive,
              exit: css.exit,
              exitActive: css.exitActive,
            }}
            unmountOnExit
          >
            <>
              {activeSlideData && <h1>&nbsp;{activeSlideData.title}&nbsp;</h1>}
              {activeSlideData && (
                <div className={css.description} dangerouslySetInnerHTML={{ __html: activeSlideData.description }} />
              )}

              <div className={css.articles}>
                {activeSlideData &&
                  activeSlideData.articles &&
                  activeSlideData.articles.map((article: any, index: number) => (
                    <a href={article.link} target="_blank" key={article.link} className={css.articleItem}>
                      <img src={fileLink(article.poster)} alt={article.title} className={css.poster} />
                      <div className={css.title}>{article.title}</div>
                    </a>
                  ))}
              </div>
            </>
          </CSSTransition>

          <div className={css.externalArrowControls}>
            <ExternalPrevArrow onClick={onPrevControlClick} className={activeIndex === 0 && 'disabled'} />
            <ExternalNextArrow onClick={onNextControlClick} className={activeIndex === data.length - 1 && 'disabled'} />
          </div>
        </div>

        <footer className={css.footer}>
          <Shares className={css.footerShares} />

          <div className={css.rabbiCredits}>
            Developed by{' '}
            <a href="https://www.rabbi.co.il/" target="_blank">
              Rabbi Interactive
            </a>
          </div>
        </footer>
      </div>
      {/* ) : (
         <Loader />
      )} */}
    </>
  );
};

export default MobileApp;

function formatDate(date: string) {
  const _date = new Date(date);
  return _date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
}

function getTimeDifference(dateString: string) {
  // Parse the input date string into a Date object
  const targetDate = parseISO(dateString);

  // Convert startTimeString ('hh:mm:ss') to total seconds
  // const [hours, minutes, seconds] = startTimeString.split(':').map(Number);
  // const startTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

  // Get the current date
  const current = new Date();
  const currentTimeInSeconds = current.getUTCHours() * 3600 + current.getUTCMinutes() * 60 + current.getUTCSeconds();

  const timeInSeconds = targetDate.getUTCHours() * 3600 + targetDate.getUTCMinutes() * 60 + targetDate.getUTCSeconds();

  // Calculate the difference in seconds between currentDate and adjustedDate
  const differenceInSec = currentTimeInSeconds - timeInSeconds;

  // Convert the difference to hh:mm:ss format
  // const diffHours = Math.floor(Math.abs(differenceInSec) / 3600)
  //   .toString()
  //   .padStart(2, '0');
  // const diffMinutes = Math.floor((Math.abs(differenceInSec) % 3600) / 60)
  //   .toString()
  //   .padStart(2, '0');
  // const diffSeconds = (Math.abs(differenceInSec) % 60).toString().padStart(2, '0');
  // const differenceString = `${diffHours}:${diffMinutes}:${diffSeconds}`;
  console.info({
    // differenceInSeconds: differenceInSec,
    // differenceFormatted: differenceString,
    // startTimeFormatted: startTimeString,
    startTime: differenceInSec,
  });
  return differenceInSec;
}

function findItemForCurrentTime(
  data: TimelineItem[],
): { item: TimelineItem; index: number; timeDifference?: number } | undefined {
  const current = new Date();

  // Extract current time in hours, minutes, and seconds
  const currentTimeInSeconds = current.getUTCHours() * 3600 + current.getUTCMinutes() * 60 + current.getUTCSeconds();

  // Convert item dates to time-only format for comparison
  const sortedData = data
    .map((item, index) => {
      const date = parseISO(item.date);
      const timeInSeconds = date.getUTCHours() * 3600 + date.getUTCMinutes() * 60 + date.getUTCSeconds();

      return {
        ...item,
        timeInSeconds,
        originalIndex: index, // Preserve original index
      };
    })
    .sort((a, b) => a.timeInSeconds - b.timeInSeconds);

  // Find the item with a time within the current time and the next item’s time
  for (let i = 0; i < sortedData.length; i++) {
    const item = sortedData[i];
    const nextItem = sortedData[i + 1];

    if (item.timeInSeconds <= currentTimeInSeconds && (!nextItem || currentTimeInSeconds < nextItem.timeInSeconds)) {
      return {
        item: {
          ...item,
          date: item.date, // Keep the original date
        },
        index: item.originalIndex, // Return the original index
        timeDifference: currentTimeInSeconds - item.timeInSeconds,
      };
    }
  }

  return undefined; // No item found within the given range
}

export const fileLink = (data: string) => {
  return data.replace(
    'https://storage.googleapis.com/mako-n12-7-10-video.appspot.com',
    'https://october7th-n12.cdn-il.com',
  );
};

const data = [
  {
    _id: '64da2b4a9f1b0a001c4e4b20',
    name: 'Admin User 1',
    title: 'Breaking News 1',
    description: 'aaa',
    videoDuration: '02:20:15',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T01:33:00.000Z',
    articles: [
      {
        title: 'Article 1',
        poster: 'https://example.com/poster1.jpg',
        link: 'https://example.com/article1',
      },
    ],
    order: 1,
    createdAt: '2024-08-10T12:00:00Z',
    updatedAt: '2024-08-10T12:30:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b21',
    name: 'Admin User 2',
    title: 'Breaking News 2',
    description: 'aaa',
    videoDuration: '02:00:00',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T11:10:00.000Z',
    articles: [
      {
        title: 'Article 2',
        poster: 'https://example.com/poster2.jpg',
        link: 'https://example.com/article2',
      },
    ],
    order: 2,
    createdAt: '2024-08-10T12:15:00Z',
    updatedAt: '2024-08-10T12:45:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b22',
    name: 'Admin User 3',
    title: 'Breaking News 3',
    description: 'aaa',
    videoDuration: '01:02:45',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T12:20:00.000Z',
    articles: [
      {
        title: 'Article 3',
        poster: 'https://example.com/poster3.jpg',
        link: 'https://example.com/article3',
      },
    ],
    order: 3,
    createdAt: '2024-08-10T12:30:00Z',
    updatedAt: '2024-08-10T13:00:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b23',
    name: 'Admin User 4',
    title: 'Breaking News 4',
    description: 'aaa',
    videoDuration: '00:03:00',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T13:50:00.000Z',
    articles: [
      {
        title: 'Article 4',
        poster: 'https://example.com/poster4.jpg',
        link: 'https://example.com/article4',
      },
    ],
    order: 4,
    createdAt: '2024-08-10T12:45:00Z',
    updatedAt: '2024-08-10T13:15:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b24',
    name: 'Admin User 5',
    title: 'Breaking News 5',
    description: 'aaa',
    videoDuration: '00:04:15',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T13:05:00.000Z',
    articles: [
      {
        title: 'Article 5',
        poster: 'https://example.com/poster5.jpg',
        link: 'https://example.com/article5',
      },
    ],
    order: 5,
    createdAt: '2024-08-10T13:00:00Z',
    updatedAt: '2024-08-10T13:30:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b25',
    name: 'Admin User 6',
    title: 'Breaking News 6',
    description: 'aaa',
    videoDuration: '00:05:30',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T14:30:00.000Z',
    articles: [
      {
        title: 'Article 6',
        poster: 'https://example.com/poster6.jpg',
        link: 'https://example.com/article6',
      },
    ],
    order: 6,
    createdAt: '2024-08-10T13:15:00Z',
    updatedAt: '2024-08-10T13:45:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b26',
    name: 'Admin User 7',
    title: 'Breaking News 7',
    description: 'aaa',
    videoDuration: '00:06:45',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T23:40:00.000Z',
    articles: [
      {
        title: 'Article 7',
        poster: 'https://example.com/poster7.jpg',
        link: 'https://example.com/article7',
      },
    ],
    order: 7,
    createdAt: '2024-08-10T13:30:00Z',
    updatedAt: '2024-08-10T14:00:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b27',
    name: 'Admin User 8',
    title: 'Breaking News 8',
    description: 'aaa',
    videoDuration: '00:07:00',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T23:50:00.000Z',
    articles: [
      {
        title: 'Article 8',
        poster: 'https://example.com/poster8.jpg',
        link: 'https://example.com/article8',
      },
    ],
    order: 8,
    createdAt: '2024-08-10T13:45:00Z',
    updatedAt: '2024-08-10T14:15:00Z',
  },
  {
    _id: '64da2b4a9f1b0a001c4e4b28',
    name: 'Admin User 9',
    title: 'Breaking News 9',
    description: 'aaa',
    videoDuration: '00:07:00',
    videoUrl:
      'https://mako-vod.akamaized.net/i/VOD/KESHET/Rokdim_im_kochavim/S03/EP/rokdim_im_kochavim3_23_070824_vod_arc25871/rokdim_im_kochavim3_23_070824_vod_arc25871_,550,850,1400,2200,.mp4.csmil/master.m3u8',
    date: '2023-10-07T23:55:00.000Z',
    articles: [
      {
        title: 'Article 8',
        poster: 'https://example.com/poster8.jpg',
        link: 'https://example.com/article8',
      },
    ],
    order: 9,
    createdAt: '2024-08-10T13:45:00Z',
    updatedAt: '2024-08-10T14:15:00Z',
  },
];
