import React from 'react';
import cn from 'classnames';
import Icon from 'src/components/common/Icon';

import css from './Arrows.module.scss';

export function DefaultNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div className={cn(css[className], css.defaultNext)} style={{ ...style }} onClick={onClick}>
      <Icon type="arrow-left" />
    </div>
  );
}

export function DefaultPrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div className={cn(css[className], css.defaultPrev)} style={{ ...style }} onClick={onClick}>
      <Icon type="arrow-left" className={css.iconPrev} />
    </div>
  );
}

export function ExternalNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div className={cn(css[className], css.externalNext)} style={{ ...style }} onClick={onClick}>
      <Icon type="arrow-left" />
    </div>
  );
}

export function ExternalPrevArrow(props: any) {
  const { className, style, onClick } = props;

  return (
    <div className={cn(css[className], css.externalPrev)} style={{ ...style }} onClick={onClick}>
      <Icon type="arrow-left" className={css.iconPrev} />
    </div>
  );
}
